import * as React from 'react';

// Components
import Layout from '../components/layout';
import LexiqueSidebar from '../components/lexique-sidebar';
import CtaImage from '../components/cta-image';
import Seo from '../components/seo';
import {
    Article as ArticleComponent,
    HeadTitle
} from '@monemprunt/design-system';
import { Link as LinkComponent } from 'gatsby';

// utils
import { graphql } from 'gatsby';
import { clsx } from '@monemprunt/design-system';

// types & interfaces
import type {
    Article,
    LexiqueTerm,
    RelatedArticle,
    TemplatePageProps
} from '../lib/types';

interface LexiqueTermPageData {
    term: LexiqueTerm & {
        articles: Array<Pick<Article, 'title' | 'SEO'>>;
    };
}

const LexiqueTermPage: React.FC<
    TemplatePageProps<
        LexiqueTermPageData,
        {
            relatedArticles: Array<RelatedArticle>;
        }
    >
> = ({
    data: { term },
    location: { pathname },
    pageContext: { relatedArticles }
}) => {
    const [sideBarOpen, setSideBarOpen] = React.useState(false);

    return (
        <Layout active="#infos">
            <Seo
                {...term.SEO}
                slug={`/lexique/${term.SEO.slug}`}
                pathname={pathname}
            />
            <main>
                <div className="md:grid md:grid-cols-10 lg:grid-cols-12 xl:grid-cols-12">
                    {/* SideBar desktop */}
                    <div
                        className={clsx(
                            'bg-light-50 pb-40 pt-40',
                            'md:pt-36 md:col-span-3  md:static md:px-0 md:block',
                            {
                                hidden: !sideBarOpen
                            }
                        )}>
                        <LexiqueSidebar
                            className="w-full md:sticky md:top-36"
                            activeTerm={term.name}
                            onToggle={() => setSideBarOpen(false)}
                            open
                        />
                    </div>

                    <section
                        className={clsx(
                            'w-full space-y-4 px-5 pt-40 mb-52',
                            'md:pb-10 md:pt-36 md:col-span-7 md:block md:px-10',
                            'lg:col-span-9',
                            {
                                hidden: sideBarOpen
                            }
                        )}>
                        <div className="w-full sticky top-40 md:hidden">
                            {/* SideBar mobile */}
                            <LexiqueSidebar
                                className={`w-full`}
                                activeTerm={term.name}
                                onToggle={() => {
                                    scroll({
                                        top:
                                            document.querySelector('main')
                                                .offsetTop - 80,
                                        behavior: 'smooth'
                                    });
                                    setSideBarOpen(true);
                                }}
                            />
                        </div>

                        <h1 className="text-3xl font-bold text-center">
                            {term.name}
                        </h1>

                        {/* Article  */}
                        <ArticleComponent
                            customLink={(props) => <LinkComponent {...props} />}
                            content={term.definition}
                            className="max-w-[1200px]"
                            callToAction={() => (
                                <div className="flex justify-center">
                                    <CtaImage />
                                </div>
                            )}
                        />

                        {relatedArticles.length > 0 && (
                            <>
                                <HeadTitle label="Articles sur le sujet" />
                                <ul className="pl-4">
                                    {relatedArticles.map((article) => (
                                        <li
                                            key={article.id}
                                            className="before:content-['\2022'] before:text-secondary-100 before:inline-block before:font-extrabold before:w-[1em] before:h-[em] before:ml-[-1em]">
                                            <LinkComponent
                                                to={`${article.basePath}/${article.SEO.slug}`}
                                                className="font-bold text-secondary-100 hover:underline">
                                                {article.title}
                                            </LinkComponent>
                                        </li>
                                    ))}
                                </ul>
                            </>
                        )}
                    </section>
                </div>
            </main>
        </Layout>
    );
};

export default LexiqueTermPage;

export const query = graphql`
    query LexiqueTerm($id: Int) {
        term: strapiLexique(strapiId: { eq: $id }) {
            name: term
            SEO {
                slug
                description
                title
            }

            definition
            articles {
                title
                SEO {
                    slug
                }
            }
        }
    }
`;
