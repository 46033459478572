import * as React from "react";
import Icon from "./icon";

export interface MobilePaginationProps {
  numPages: number;
  currentPage: number;
  onPageChanged: (page: number) => void;
}

const MobilePagination: React.FC<MobilePaginationProps> = ({
  numPages,
  currentPage,
  onPageChanged,
}) => {
  return (
    <div className={"grid grid-cols-4 gap-2 items-center"}>
      <button
        disabled={currentPage === 1}
        className={`${
          currentPage <= 1
            ? "bg-gray-400/30 text-gray-500 cursor-not-allowed"
            : "bg-light-100 text-secondary-100"
        } flex items-center justify-center rounded-md p-2 focus:outline-none focus:ring-2 ring-dark-100/50`}
        onClick={() => onPageChanged(currentPage - 1)}
      >
        <Icon icon={"arrow-left"} className={"h-5"} />
      </button>

      <div
        className={
          "col-span-2 border border-dark-100 py-1 px-4 rounded-md bg-white text-center"
        }
      >
        {currentPage} / {numPages}
      </div>

      <button
        disabled={currentPage >= numPages}
        className={`${
          currentPage >= numPages
            ? "bg-gray-400/30 text-gray-500 cursor-not-allowed"
            : "bg-light-100 text-secondary-100"
        } flex items-center justify-center rounded-md p-2 focus:outline-none focus:ring-2 ring-dark-100/50`}
        onClick={() => onPageChanged(currentPage + 1)}
      >
        <Icon icon={"arrow-right"} className={"h-5"} />
      </button>
    </div>
  );
};

export default MobilePagination;
