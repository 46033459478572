import { graphql, Link, useStaticQuery } from "gatsby";
import * as React from "react";
import { useState } from "react";
import { LexiqueTerm } from "../lib/types";
import Input from "./custom-input";
import { debounce, linkWithSlash } from "../lib/functions";
import Icon from "./icon";
import MobilePagination from "./mobile-pagination";
import { usePagination } from "../lib/hooks";

export interface LexiqueSidebarProps {
  activeTerm: string;
  defaultTerms?: Array<LexiqueTerm>;
  className?: string;
  open?: boolean;
  onToggle: () => void;
}

const LexiqueSidebar: React.FC<LexiqueSidebarProps> = ({
  activeTerm,
  defaultTerms = [],
  className = "",
  open = false,
  onToggle,
}) => {
  // query to get all the theme & subjects from "guide immo"
  let terms: Array<LexiqueTerm>;
  try {
    const result: {
      lexiques: { terms: LexiqueTerm[] };
    } = useStaticQuery(graphql`
      {
        lexiques: allStrapiLexique {
          terms: nodes {
            name: term
            SEO {
              slug
            }
          }
        }
      }
    `);

    // get themes
    const {
      lexiques: { terms: lexiqueTerms },
    } = result;
    terms = lexiqueTerms;
  } catch (error) {
    terms = defaultTerms;
  }

  const [searchTerm, setSearchTerm] = useState("");

  const filteredTerms =
    searchTerm.length > 0
      ? terms.filter(term =>
          term.name.toLowerCase().includes(searchTerm.toLowerCase())
        )
      : terms;

  const {
    maxPage: numPages,
    currentPage: page,
    jump: setPage,
    currentData,
  } = usePagination({
    data: filteredTerms,
    perPage: 30,
  });

  return (
    <aside
      className={`${className} bg-light-50 p-3 flex flex-col space-y-4
      md:max-h-screen lg:overflow-y-scroll
       ${!open && "shadow-md"} lg:shadow-none`}
    >
      {/* Placeholder when closed */}
      <div className={`md:hidden flex justify-between items-center `}>
        <div className={"content-center"}>
          {!open ? "Tous les termes" : "Fermer"}
        </div>
        <button
          className={"p-2"}
          aria-label={"Voir tous les termes"}
          onClick={() => onToggle && onToggle()}
        >
          <Icon icon={!open ? "menu" : "close"} className={"h-5"} />
        </button>
      </div>

      {/* Input to filter terms */}
      <div className={`${!open && "hidden"} md:block`}>
        <Input
          type="search"
          name="search"
          id="search"
          placeholder="Rechercher un terme"
          initalValue={searchTerm}
          className={`${!open && "hidden"} md:block`}
          onClear={() => setSearchTerm("")}
          // @ts-ignore
          onChange={debounce((value: string) => {
            setSearchTerm(value.trim());
            setPage(1);
          }, 300)}
        />
      </div>

      {/* The sidebar content */}
      <div className={`${!open && "hidden"} md:block`}>
        <nav className={"space-y-2"}>
          <Link to="/lexique/" className={"font-bold block text-secondary-100"}>
            Tous les termes
          </Link>
          <ul
            className={
              "flex flex-col pl-4 max-h-[40vh]  overflow-y-scroll md:max-h-[50vh] lg:max-h-[55vh] xl:max-h-[55vh]"
            }
          >
            {currentData().map((term, i) => (
              <li key={i}>
                <Link
                  to={linkWithSlash(`/lexique/${term.SEO.slug}`)}
                  className={`text-sm mt-0 grid grid-cols-10 hover:text-secondary-100 ${
                    activeTerm === term.name ? "text-dark-100" : "text-dark-50"
                  }`}
                >
                  <span
                    className={`col-span-1 border-l-2 ${
                      activeTerm === term.name
                        ? "border-secondary-100"
                        : "border-gray-400/30"
                    }`}
                  />
                  <span className={"col-span-9 py-1"}>{term.name}</span>
                </Link>
              </li>
            ))}
          </ul>
          <div className={"w-full pt-3"}>
            {numPages > 1 && (
              <MobilePagination
                numPages={numPages}
                currentPage={page}
                onPageChanged={page => setPage(page)}
              />
            )}
          </div>
        </nav>
      </div>
    </aside>
  );
};

export default LexiqueSidebar;
